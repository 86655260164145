
import Confirm from '@/components/core/Confirm.vue';
import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const settings = namespace('settings');
const welcome = namespace('welcome');

@Component
export default class Login extends Vue {
  loading = false;
  valid = false;
  codes = [];

  @settings.Action
  signIn!: (user: any) => Promise<any>;

  @welcome.State
  joinData!: any;

  @welcome.State
  regToken!: string;

  @welcome.State
  studentJoinData!: any;

  get hasRegToken() {
    return CommonUtils.hasText(this.regToken);
  }

  $refs!: {
    confirm: Confirm;
    studentCodeForm: Vue & { validate: () => boolean; resetValidation: () => void };
  };

  get requiredRule(): Array<any> {
    return [(v: any) => CommonUtils.isNotEmpty(v) || 'Required.'];
  }

  submitStudentCode() {
    if (this.$refs.studentCodeForm.validate()) {
      if (this.hasRegToken) {
        this.$store.commit('welcome/setJoinCode', '', { root: true });
        this.$store.commit('welcome/setStudentJoinCode', this.codes.join(','), { root: true });
        this.$router.push({ path: '/auth/onboarding', query: { src: 'student-codes' } })
      } else {
        this.$router.push({ path: '', query: { sc: this.codes.join(',') } })
      }
    }
  }
}
