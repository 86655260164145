
































































import Confirm from '@/components/core/Confirm.vue';
import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const settings = namespace('settings');
const welcome = namespace('welcome');

@Component
export default class Login extends Vue {
  loading = false;
  valid = false;
  codes = [];

  @settings.Action
  signIn!: (user: any) => Promise<any>;

  @welcome.State
  joinData!: any;

  @welcome.State
  studentJoinData!: any;

  $refs!: {
    confirm: Confirm;
    studentCodeForm: Vue & { validate: () => boolean; resetValidation: () => void };
  };

  get requiredRule(): Array<any> {
    return [(v: any) => CommonUtils.isNotEmpty(v) || 'Required.'];
  }

  submitStudentCode() {
    if (this.$refs.studentCodeForm.validate()) {
      this.$router.push({ path: '', query: { sc: this.codes.join(',') } })
    }
  }
}
