var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "studentCodeForm",
      class: { "mt-10": _vm.$vuetify.breakpoint.smAndDown },
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitStudentCode.apply(null, arguments)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-toolbar",
                { staticClass: "text-h4 text-center", attrs: { flat: "" } },
                [
                  _c("div", { staticClass: "justify-center fill-width" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "https://cdn.planbook.com/images/planbook-logo-blue.svg",
                        alt: _vm.$t("app.name")
                      }
                    })
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "px-10 pb-2 pt-10" },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-6 primary--text",
                  class: { "text-left": _vm.$vuetify.breakpoint.smAndDown }
                },
                [_vm._v(" " + _vm._s(_vm.$t("studentJoinCodesLabel")) + " ")]
              ),
              _c("v-combobox", {
                attrs: {
                  outlined: "",
                  items: [],
                  label: _vm.$t("studentJoinCodesLabel"),
                  multiple: "",
                  rules: _vm.requiredRule
                },
                model: {
                  value: _vm.codes,
                  callback: function($$v) {
                    _vm.codes = $$v
                  },
                  expression: "codes"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            {
              staticClass: "px-10 pb-8 pt-0 justify-center",
              class: { "welcome-card-actions": _vm.$vuetify.breakpoint.mdAndUp }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-10 mb-3",
                  attrs: {
                    depressed: "",
                    color: "error",
                    dark: "",
                    width: "175px",
                    type: "submit",
                    loading: _vm.loading
                  }
                },
                [_vm._v(_vm._s(_vm.$t("continueLabel")))]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ml-0 mb-3",
                  attrs: {
                    depressed: "",
                    color: "info",
                    dark: "",
                    width: "175px",
                    to: "/auth/signup",
                    loading: _vm.loading
                  }
                },
                [_vm._v(_vm._s(_vm.$t("cancelLabel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }